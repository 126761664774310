import {
    handleQueryResolve
} from '../utils'

export default function(PatientID) {
    let where
    let attrs
    if (Array.isArray(PatientID)) {
        where = [`PatientId IN (${PatientID.join(',')})`]
    } else {
        attrs = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID, // to support like
        }]
        where = ['PatientId = @PatientID']
    }

    // console.log('where', where)
    const query = `
  SELECT 
  PlanOrder
  ,PlanCode
  ,GroupID
  ,PlanID
  ,CarrierID
  ,RelationShip
  ,Inactive
  ,SmartCard
FROM Fillware.dbo.PatientPlans
WHERE ${where}
Order by PlanOrder ASC
          `

    // console.log(query)

    return this.query(query, attrs).then(handleQueryResolve)
}

// old query
// SELECT
// PlanOrder
// ,PlanCode
// ,GroupID
// ,PlanID
// ,CarrierID
// ,ExpiryDate
// ,RelationShip
// ,CardHolder
// ,PatientCode
// ,DefaultPaymentType
// ,Inactive
// ,BIN
// ,BillingType
// ,SmartCard
// ,Trillium
// ,OHIPPlus
// ,OHIPPlus2
// FROM Fillware.dbo.PatientPlans
// WHERE ${where}
// Order by PlanOrder ASC